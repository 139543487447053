@import "vars";

html{
    font-size: 18px;
}

body {
    font-family: 'Open Sans', sans-serif;
    color:black;
    background-size: cover !important;
    background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), $primary url("../../assets/img/bg.png") no-repeat center;
}

.z-index{
    z-index: 100;
}

h1{
    color: #bb0b0b;
}

p{
    font-size: 1.2rem;
}

nav {
    background:$primary;
    color: #ff0000;
}


.svg-bg{
    background-color:  #f1f2f3;
}

nav{
    font-size: .79rem;
    font-weight: bold;
}

.navbar-dark .navbar-nav .nav-link {
    color: #333;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
    color: #bb0b0b;
}

a {
    color:#333333;
    text-decoration: none;
}


.titre{
    color:#333;
    font-size: 120%;

}


.black-overly{
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.15);
}

.material{
    background-color: $primary;
    border: none;
}

.tossing:hover{
    animation-name: tossing;
    -webkit-animation-name: tossing;

    animation-duration: 2.5s;
    -webkit-animation-duration: 2.5s;

    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
    0% {
        transform: rotate(-4deg);
        }

    50% {
        transform: rotate(4deg);
        }

    100% {
        transform: rotate(-4deg);
        }
}

@-webkit-keyframes tossing {
    0% {
        -webkit-transform: rotate(-4deg);
        }

    50% {
        -webkit-transform: rotate(4deg);
        }

    100% {
        -webkit-transform: rotate(-4deg);
        }
}

.mt-below-svg{
    margin-top:6rem;
}

.img-pressbook{
    object-fit: scale-down;
}

.photogalerie{
    font-size: 3vh;
}

#lg-actual-size{
    display:none;
}
