#counter{
    text-align: center;
    margin-top: 30px;
}
#counter span {
    display: inline-block;
    padding: 6px 6px 4px;
    border-radius: 3px;
    background: #b09dc4;
}
