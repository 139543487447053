.imageGallery {
    position: relative;
    display: inline-block; /* Assurez-vous que la div occupe seulement l'espace nécessaire */
}

.imageGallery img {
    display: block;
    width: 100%;
    height: auto;
}

.imageGallery .overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Couleur de fond avec une transparence */
    color: #fff;
    opacity: 0; /* Masque initialement le texte */
    transition: opacity 0.3s ease; /* Ajoute une transition en douceur */
    z-index: 2;
}

.imageGallery:hover .overlay {
    opacity: 1; /* Affiche le texte au survol */
}

.sticky {
    position: fixed !important;
    top: 10 !important;
    z-index: 9999 !important;
}

.categories:hover {
    color: black !important;
}

.categories {
    color: #2b2b2b !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.word-wrap{
    overflow-wrap: break-word;
}

.card-l:hover .card-img-overlay{
    background: rgba(0, 0, 0, 0.5);
}

.card-title-l{
    margin-top: 600px;
    color: #f1f2f3;
    -webkit-transition: margin-top 0.3s ease 0s;
    -o-transition: margin-top 0.3s ease 0s;
    transition: margin-top 0.3s ease 0s;
}

.card-l:hover .card-title-l{
    margin-top: 1%;
}

.card-l > img {
    -webkit-transition: -webkit-transform 0.15s ease 0s;
    -moz-transition: -moz-transform 0.15s ease 0s;
    -o-transition: -o-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    height: 100%;
    width: 100%;
}

.card-l:hover > img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}

.h-max-p{
    max-height: 100%;
}

.object-fit-cover{
    object-fit: contain;
    height: 78vh;
}

.fix_height{
    height: auto;
}

.bresbook-card-body{
    width: 0;
}

@media only screen and (max-width: 1200px) {
    html{
        font-size: 15px;
    }

    .navbar-brand .titre{
        display: none;
    }
}

.press{
    max-height: 300px;
}

.social:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.social {
    -webkit-transform: scale(0.8);
    /* Browser Variations: */

    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    color: #bb0b0b;

}

@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:200,900');

:root {
    --text-color: hsla(210, 50%, 85%, 1);
    --shadow-color: hsla(210, 40%, 52%, .4);
    --btn-color: #00b3db;
    --bg-color: #141218;
}

* {
    box-sizing: border-box;
}



a.button {
    position: relative;
    padding: 10px 20px;
    border: none;
    text-decoration: none;
    background: none;
    cursor: pointer;
    height: 50px;
    font-family: "Source Code Pro", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 30px;
    color: var(--text-color);


    background-color: var(--btn-color);
    box-shadow: var(--shadow-color) 2px 2px 22px;
    border-radius: 4px;
    z-index: 0;
    overflow: hidden;
    top: -1rem;
    right: 22%;
}

a.button:focus {
    outline-color: transparent;
    box-shadow: var(--btn-color) 2px 2px 22px;
}

.right::after, a.button::after {
    content: var(--content);
    display: block;
    position: absolute;
    white-space: nowrap;
    padding: 40px 40px;
    pointer-events: none;
}

a.button::after {
    font-weight: 200;
    top: -30px;
    left: -20px;
}

.right, .left {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.right {
    left: 66%;
}

.left {
    right: 66%;
}

.right::after {
    top: -30px;
    left: calc(-66% - 20px);

    background-color: var(--bg-color);
    color: transparent;
    transition: transform .4s ease-out;
    transform: translate(0, -90%) rotate(0deg)
}

a.button:hover .right::after {
    transform: translate(0, -47%) rotate(0deg)
}

a.button .right:hover::after {
    transform: translate(0, -50%) rotate(-7deg)
}

a.button .left:hover ~ .right::after {
    transform: translate(0, -50%) rotate(7deg)
}

@media only screen and (max-width: 767px) {
    a.button {
        top: -22rem;
        right: 0%;
    }
}





