.carousel .img-carousel {
    height: 93vh;
}

@media screen and (max-width:768px) and (min-width:577px) {
    .carousel .img-carousel {
        height: 64vh;
  }
}

@media screen and (max-width:576px) and (min-width: 376px) {
    .carousel .img-carousel {
        height: 50vh;
  }
}

@media screen and (max-width:576px) {
    .carousel .img-carousel {
        height: 36vh;
  }
}

